import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { desktop } from "../styles/breakpoints";

import {
  rem,
  maxWidth,
  maxWidthpx,
  colors,
  fontSize,
  fontWeights,
} from "./utils";
import { moveInCircle } from "./animations";

export const clipBox = css`
  overflow: hidden;
`;

export const mobileGutter = css`
  padding-left: ${rem(30)};
  padding-right: ${rem(30)};

  @media (min-width: ${rem(maxWidthpx + 60)}) {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const Container = styled.div`
  max-width: ${maxWidth};
  ${mobileGutter};
  margin: auto;
`;

export const container = css`
  max-width: ${maxWidth};
  ${mobileGutter};
  margin: auto;
`;

export const InnerContainer = styled.div`
  max-width: ${rem(960)};
  ${mobileGutter};
  margin: auto;
`;

export const innerContainer = css`
  max-width: ${rem(960)};
`;

export const h1 = css`
  ${fontSize(24, 34)};
  font-weight: ${fontWeights.medium};

  @media (${desktop}) {
    ${fontSize(34, 50)};
  }
`;

export const H1 = styled.h1`
  ${h1};
`;

export const h2 = css`
  ${fontSize(24, 34)};
  font-weight: ${fontWeights.book};

  @media (${desktop}) {
    ${fontSize(30, 46)};
  }
`;

export const H2 = styled.h2`
  ${h2};
`;

export const h3 = css`
  ${fontSize(24, 34)};
  font-weight: ${fontWeights.black};
`;

export const H3 = styled.h3`
  ${h3};
`;

export const h4 = css`
  ${fontSize(20, 30)};
  font-weight: ${fontWeights.black};
`;

export const H4 = styled.h4`
  ${h4};
`;

export const h5 = css`
  ${fontSize(20, 30)};
  font-weight: ${fontWeights.black};
`;

export const H5 = styled.h5`
  ${h5};
`;

export const h6 = css`
  ${fontSize(20, 30)};
  font-weight: ${fontWeights.book};
`;

export const H6 = styled.h6`
  ${h6};
`;

export const P = css`
  ${fontSize(24, 40)};
  font-weight: ${fontWeights.book};
`;

export const sectionTxt = css`
  ${fontSize(18, 24)};
  font-weight: ${fontWeights.black};
  text-transform: uppercase;
`;

export const bannerTxt = css`
  ${fontSize(24, 34)};
  font-weight: ${fontWeights.medium};

  @media (${desktop}) {
    ${fontSize(40, 50)};
  }
`;

export const sectionTxtCenter = css`
  ${sectionTxt};
  text-align: center;
`;

export const StatTxt = css`
  ${fontSize(40, 50)};
  font-weight: ${fontWeights.black};
`;

export const bodyTxt = css`
  ${fontSize(18, 28)};
  font-weight: ${fontWeights.book};
`;

export const legalText = css`
  ${fontSize(15, 23)};
  font-weight: ${fontWeights.book};
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const cleanList = css`
  list-style: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
`;

export const bold = css`
  font-weight: ${fontWeights.bold};
`;

export const A = styled.a`
  color: ${colors.purple};
  text-decoration: underline;

  &:active,
  &:hover,
  &:focus {
    color: ${colors.purple};
  }

  &:hover {
    text-decoration: none;
  }
`;

const circleColor = ({ color = colors.orangeLight }) => css`
  background-color: ${color};
`;

export const Circle = styled.div`
  display: block;
  max-width: 100%;
  height: 0;
  padding-top: 100%;
  border-radius: 100%;
  ${({ animate }) => animate && moveInCircle};
  ${({ animateDir }) => animateDir && `animation-direction: ${animateDir};`};
  ${({ animateDelay }) => animateDelay && `animation-delay: ${animateDelay};`};
  ${circleColor};
`;

export const orange = css`
  color: ${colors.orange};
`;

export const centerTxt = css`
  ${innerContainer};
  text-align: center;
`;

export const SRText = styled.span`
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
`;

export const txtUp = css`
  text-transform: uppercase;
`;

export const circleImg = css`
  border-radius: 100%;
  overflow: hidden;
  transform: translateZ(0);
`;

export const noMargin = css`
  margin: 0 auto ${rem(20)};
`;

export const halfMargin = css`
  margin: 0 auto ${rem(20)};
`;

export const centerMargin = css`
  margin-left: auto;
  margin-right: auto;
`;

export const flex = css`
  display: flex;
`;

export const flexCenterAll = css`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const VisuallyHidden = styled.div`
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap; /* added line */
`;

export const halfPadRight = css`
  padding-right: ${rem(15)};
`;

export const halfPadLeft = css`
  padding-left: ${rem(15)};
`;

export const Tagline = styled.div`
  ${legalText};
  ${centerTxt};
  ${centerMargin};
  padding-top: ${rem(15)};
  padding-bottom: ${rem(15)};

  ${({ pushBot }) => pushBot && `margin-bottom: ${rem(100)};`}
`;

export const marginXtraBot = css`
  margin-bottom: ${rem(60)};

  @media (${desktop}) {
    margin-bottom: ${rem(100)};
  }
`;

export const Tract = styled.h3`
  ${h3};
  @media (${desktop}) {
    ${fontSize(34, 50)};
  }
  text-align: center;
`;